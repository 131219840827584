import React from "react";
import { NextPage } from "next";
import { LoginForm } from "@features/users/components";
import { Flex, Box, Heading, Center, Spinner } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useAuth } from "@features/users/hooks";
import Layout, { HEADER_HEIGHT } from "@features/ui/StandardLayout";

// trigger redeploy

const Login: NextPage = () => {
    const router = useRouter();
    const { user } = useAuth();

    const redirect = router.query.redirect as string || "/landing-pages";

    const handleSubmit = () => {
        router.push(redirect);
    };

    React.useEffect(() => {
        if (user) {
            router.push("/landing-pages");
        }
    }, [user]);

    if(user === undefined || user){
        return (
            <Center minH="100vh">
                <Spinner />
            </Center>
        )
    }

    return (
        <Layout>
            <Flex minH={`calc(100vh - ${HEADER_HEIGHT}px)`} align="center" justify="center">
                <Box w="100%" maxW={500} px="4">
                    <Heading mb={10}>Sign in to <Box textTransform="uppercase" as="span">Bithub</Box></Heading>

                    <LoginForm onSubmit={handleSubmit} />
                </Box>
            </Flex>
        </Layout>
    );
};

export default Login;
